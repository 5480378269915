
@font-face {
	font-family:	'Montserrat' ;
	src: url('./Fontes/Montserrat-Regular_0.ttf');
}
@font-face {
	font-family:	'Montserrat-ExtraBold' ;
	src: url('./Fontes/Montserrat-ExtraBold_0.ttf');
}
@font-face {
	font-family:	'Montserrat-Bold' ;
	src: url('./Fontes/Montserrat-Bold_0.ttf');
}

.botaoPadrao {
	background-color: #e5b54a  !important;
	width: 120px !important;
	color: #383330  !important;
	font-weight: 600 !important;
	border: none !important;
	font-size: 13pt !important;
	border-radius: 10px !important;
}
.botaoPadrao:hover {
	background-color: #cea244  !important;
	color: #383330  !important;
}

.link-branco {
	color: #ffffff;
	text-decoration: none;
}
.link-branco:hover {
	color: #ebebeb
}

@media(max-width: 900px ) {
	.ocultarQuandoPequeno {
		display: none;
	}
	.seMobileCentralizar {
		align-self: center !important;
		align-content: center !important;
	}
	.seMobileCentralizarTexto {
		text-align: center !important;
	}
}

@media(min-width: 900px ) {
	.ocultarQuandoGrande {
		display: none;
	}
}
